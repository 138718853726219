import React from "react";
import heroBackground from "../assets/completed.jpg";

const Hero = () => (
    <div
  className="hero min-h-screen"
  style={{
    backgroundImage: `url(${heroBackground})`,
  }}>
  <div className="hero-overlay bg-opacity-60"></div>
  <div className="hero-content text-neutral-content text-center">
    <div className="max-w-md">
      <h1 className="mb-5 text-5xl font-bold">ABOUT US</h1>
      <p className="mb-5" style={{textAlign: "center"}}>
        Learn about what we do, who we are and what makes us special
      </p>
    </div>
  </div>
</div>
);

export default Hero;