import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    document.getElementById('my_modal_3').showModal();
  };

  return (
<>
    <dialog id="my_modal_3" className="modal">
  <div className="modal-box">
    <form method="dialog">
      {/* if there is a button in form, it will close the modal */}
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
    </form>
    <h3 className="font-bold text-lg">Thank You</h3>
    <p className="py-4">We've received your feedback, thank you for getting in touch!</p>
  </div>
</dialog>

    <form className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md" method='POST' action='https://formspree.io/f/xblrbrdd'>
  <div className="form-control mb-4">
    <label className="label">
      <span className="label-text">Name</span>
    </label>
    <input
      type="text"
      name="name"
      value={formData.name}
      onChange={handleChange}
      className="input input-bordered w-full"
    />
  </div>
  <div className="form-control mb-4">
    <label className="label">
      <span className="label-text">Email</span>
    </label>
    <input
      type="email"
      name="email"
      value={formData.email}
      onChange={handleChange}
      className="input input-bordered w-full"
    />
  </div>
  <div className="form-control mb-4">
    <label className="label">
      <span className="label-text">Message</span>
    </label>
    <textarea
      name="message"
      value={formData.message}
      onChange={handleChange}
      className="textarea textarea-bordered w-full"
    ></textarea>
  </div>
  <button type="submit" className="btn btn-primary w-full">Submit</button>
</form>
</>
  );
};

export default ContactForm;
