import React from "react";
import styles from "./HomeStat.module.css";

const Stat = () => (
    <div className={`stats shadow ${styles.container}`}>

        <div className="stat place-items-center">
            <div className="stat-title">Projects<br />Completed</div>
            <div className="stat-value">8</div>
        </div>

        <div className="stat place-items-center">
            <div className="stat-title">Projects<br />Ongoing</div>
            <div className="stat-value text-customColor">2</div>
        </div>

    </div>
);
export default Stat;