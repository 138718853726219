import React from 'react';
import Diff from './HomeDiff';
import Timeline from './HomeTimeline';
import About from './HomeAbout';
import Stat from './HomeStat';
import Completed from './HomeCompletedProjects';
/*
const Home = () => (
  <>
    <Diff />
    <Timeline />
    <About />
    <Stat />
    <Completed />
  </>
);
*/
const Home = () => (
  <>
    <Diff />
    <Timeline />
    <About />
    <Stat />
  </>
);

export default Home;
