import React from "react";
import styles from "./HomeAbout.module.css";

const About = () => (
    <div className={`bg-base-200 ${styles.container}`}>
        <div className="chat chat-start">
            <h3 className="chat-bubble">Tell me more about Shahrish</h3>
        </div>
        <div className="chat chat-end">
            <p className="chat-bubble bg-customColor">At Shahrish, we transform visions into reality with precision, innovation, and a commitment to excellence. As a trusted leader in engineering and construction, we deliver high-quality solutions for residential, commercial, and industrial projects. Our experienced team ensures each project meets the highest standards of safety and sustainability. From concept to completion, we partner with clients to understand and exceed their expectations. Focusing on sustainable practices and cutting-edge technology, Shahrish builds lasting relationships and communities. Our mission is to provide innovative solutions that stand the test of time, contributing to regional growth and development. Explore our portfolio to see how we shape the future, one project at a time. Join us in engineering a better world.</p>
        </div>
    </div >
);

export default About;