import React from 'react';
import Hero from './AboutHero';
import Details from './AboutDetails';
import Carousel from './AboutCarousel';

const About = () => (
  <div>
    <Hero />
    <Details />
    <Carousel />
  </div>
);

export default About;