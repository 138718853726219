import React from "react";
import styles from "./Footer.module.css";

const Footer = () => (
    <>
        <footer className={`footer p-10 bg-base-200 text-base-content ${styles.container}`}>
            <aside>
                <p><span>Shahrish Construction Ltd</span><br />Crafting your dreams - one brick at a time</p>
            </aside>
            <nav>
                <h6 className="footer-title">Services</h6>
                <a>Construction</a>
                <a>Inspection</a>
                <a>Maintenance</a>
                <a>Structural Planning</a>
            </nav>
            <nav>
                <h6 className="footer-title">Company</h6>
                <a className="link link-hover" href="/about">About us</a>
                <a className="link link-hover" href="/contact">Contact</a>
                <a className="link link-hover" href="/services">Our Services</a>
            </nav>
            <nav>
                <h6 className="footer-title">Legal</h6>
                <a>Privacy policy</a>
            </nav>
        </footer>
        <footer className="footer footer-center p-4 bg-base-300 text-base-content">
            <aside>
                <p>Copyright © 2024 - All right reserved by Shahrish Construction Ltd</p>
            </aside>
        </footer>
    </>
);

export default Footer;