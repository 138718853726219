import React from 'react';
import styles from './Services.module.css';
import heroBackground from "../assets/completed.jpg";
import building from "../assets/building.jpg";
import carouselimg from "../assets/carouselimg.jpg";
import carouselimg2 from "../assets/carouselimg2.jpg";
import carouselimg3 from "../assets/carouselimg3.jpg";

const Services = () => (
  <div className={styles.container}>
    <div
  className="hero min-h-screen"
  style={{
    backgroundImage: `url(${heroBackground})`,
  }}>
  <div className="hero-overlay bg-opacity-60"></div>
  <div className="hero-content text-neutral-content text-center">
    <div className="max-w-md">
      <h1 className="mb-5 text-5xl font-bold">Our Services</h1>
      <p className="mb-5" style={{textAlign: "center"}}>
        See how we can help you!
      </p>
    </div>
  </div>
</div>
    <div className={styles.cardContainer}>
    <div className="card bg-base-100 image-full w-96 shadow-xl">
  <figure>
    <img
      src={building}
      alt="building" />
  </figure>
  <div className="card-body justify-center">
    <h2 className="card-title">Project Planning and Consulting</h2>
  </div>
</div>
<div className="card bg-base-100 image-full w-96 shadow-xl">
  <figure>
    <img
      src={carouselimg}
      alt="Shoes" />
  </figure>
  <div className="card-body justify-center">
    <h2 className="card-title">Site Preparation and Inspection</h2>
  </div>
</div>
<div className="card bg-base-100 image-full w-96 shadow-xl">
  <figure>
    <img
      src={carouselimg2}
      alt="Shoes" />
  </figure>
  <div className="card-body justify-center">
    <h2 className="card-title">Structural and Foundational Work</h2>
  </div>
</div>
<div className="card bg-base-100 image-full w-96 shadow-xl">
  <figure>
    <img
      src={carouselimg3}
      alt="Shoes" />
  </figure>
  <div className="card-body justify-center">
    <h2 className="card-title">Renovation and Remodeling</h2>
  </div>
</div>
{/* <div className="card bg-base-100 image-full w-96 shadow-xl">
  <figure>
    <img
      src={carouselimg3}
      alt="Shoes" />
  </figure>
  <div className="card-body justify-center">
    <h2 className="card-title">Construction Management</h2>
  </div>
</div>
<div className="card bg-base-100 image-full w-96 shadow-xl">
  <figure>
    <img
      src={carouselimg}
      alt="Shoes" />
  </figure>
  <div className="card-body justify-center">
    <h2 className="card-title">Structural Development</h2>
  </div>
</div> */}
    </div>
  </div>
);

export default Services;
