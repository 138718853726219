import React from "react";
import styles from "./AboutCarousel.module.css";
import carouselimg from "../assets/carouselimg.jpg";
import carouselimg2 from "../assets/carouselimg2.jpg";
import carouselimg3 from "../assets/carouselimg3.jpg";

const Carousel = () => (
  <div className={styles.container}>
  <div className="carousel carousel-center bg-neutral rounded-box max-w-7xl space-x-4 p-4">
  <div className="carousel-item">
    <img
      src={carouselimg}
      className="rounded-box" alt="carousel item"/>
  </div>
  <div className="carousel-item">
    <img
      src={carouselimg2}
      className="rounded-box" alt="carousel item"/>
  </div>
  <div className="carousel-item">
    <img
      src={carouselimg3}
      className="rounded-box" alt="carousel item"/>
  </div>
</div>
</div>


);

export default Carousel;