import React from 'react';
import ContactForm from './ContactForm';
import heroBackground from "../assets/completed.jpg";
import styles from "./Contact.module.css";


const Contact = () => (
  <div>
    <div
className={`hero min-h-screen ${styles.containerhero}`}
  style={{
    backgroundImage: `url(${heroBackground})`,
  }}>
  <div className="hero-overlay bg-opacity-60"></div>
  <div className="hero-content text-neutral-content text-center">
    <div className="max-w-md">
      <h1 className={`mb-5 text-5xl font-bold`}>Get in Touch</h1>
    </div>
  </div>
</div>
    <ContactForm />
  </div>
);

export default Contact;
