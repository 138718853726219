import React from "react";
import styles from "./AboutDetails.module.css";
import aboutImage from "../assets/construction.jpg";

const Details = () => (
    <div className={styles.container}>
        <div>
            <img src={aboutImage} alt="aboutSection"/>
        </div>
        <div>
        <h2>Our Past, Present and Future</h2>
        <p>Shahrish is a leading construction company renowned for its expertise in providing top-tier construction services, engineering solutions, and structural works. With a commitment to excellence and innovation, we take pride in delivering high-quality projects that meet and exceed our clients' expectations. Our team of seasoned professionals excels in managing diverse construction projects, ensuring timely completion, adherence to budget, and exceptional craftsmanship. At Shahrish, we leverage advanced technologies and innovative practices to offer robust and efficient engineering solutions, making us a trusted partner in the construction industry.<br/><br/>Our dedication to quality, integrity, and safety is at the heart of everything we do. We believe in building lasting relationships with our clients by maintaining transparency and upholding the highest standards of ethical practices. Our comprehensive range of structural works is designed to ensure durability and sustainability, contributing to the growth and development of the communities we serve. At Shahrish, we are passionate about transforming visions into reality and are committed to shaping a better future through our construction endeavors.






</p>
    </div>
    </div>
);

export default Details;